import { ChapterBranch } from '@prisma/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useCallback } from 'react'
import yup from 'src/instances/yup'
dayjs.extend(utc)

export const useYupValidationResolver = <T extends object>(
  validationSchema: yup.ObjectSchema<T>,
) =>
  useCallback(
    async (data: T) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        })

        return {
          values,
          errors: {},
        }
      } catch (errors) {
        return {
          values: {},
          errors: (errors as yup.ValidationError).inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path ?? '']: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {},
          ),
        }
      }
    },
    [validationSchema],
  )

export const isSubscriptionChapter = (chapterBranch: ChapterBranch) => {
  if (!chapterBranch.isSubscription) {
    return false
  }

  if (!chapterBranch.publishedFreeAt) {
    return true
  }

  return dayjs(chapterBranch.publishedFreeAt).utc().local().isAfter(dayjs())
}
